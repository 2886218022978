import React from "react";
import { Footer } from "../UI/organisms/Footer/Footer";
import { Navigation } from "../UI/organisms/Navigation/Navigation";
import { ProductBanner } from "../UI/organisms/ProductsBanner/ProductsBanner";
import { ProductsDownload } from "../UI/organisms/ProductsDownload/ProductsDownload";
import { ProductHow } from "../UI/organisms/ProductsHow/ProductsHow";
import { Partnership } from "../UI/organisms/Partnership/Partnership";
import { Seo } from "../UI/atoms/Seo/Seo";
export const Product = () => {
  return (
    <>
          <Seo title="Product Page | Zaptrance" description="Our Products" />

      <Navigation />
      <ProductBanner />
      <ProductsDownload />
      <ProductHow />
      <Partnership bgColor="#000"
      ctaBgColor='#2353FF'
      textColor="#fff" highlightColor="#2353FF" />
     
      <Footer bgColor="#fff" />
    </>
  );
};
