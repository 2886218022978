import React from "react";
import { Seo } from "../UI/atoms/Seo/Seo";
import { ContactPopUp } from "../UI/molecules/ContactPopUp/ContactPopUp";
export function Contact() {
  return (
    <>
      <Seo title="Contact Page | Zaptrance" description="Contact Zaptrance" />
      <ContactPopUp />
    </>
  );
}
