import React from "react";
import styles from "./Client.module.scss";
interface IClientProps {
  imgUrl: string;
  children: string;
  imgAlt: string;
}
export const Client = (props: IClientProps) => (
  <p className={styles.client}>
    <img src={props.imgUrl} alt={props.imgAlt} />
    <span>{props.children}</span>
  </p>
);
