import React from "react";
import ManAndLadyImg from "./man.png";
import Pattern from "./Pattern.png";
import styles from "./ServicesIdeas.module.scss";

export const ServicesIdeas = () => {
  return (
    <section className={styles.ideas}>
      <div>
        {/* text part  */}
        <p>Struggling with your idea?</p>
        <h4>We are the solution
to your projects.</h4>
        <img src={Pattern} alt="Dots" />
      </div>
      <div>
        {/* img part  */}
        <img
          src={ManAndLadyImg}
          alt="A man and a lady looking at a computer screen"
        />
      </div>
    </section>
  );
};
