import React from "react";
import styles from "./ServicesBanner.module.scss";
import ZapIcon from "./yellow-zap.svg";
import { ServiceAtom } from "../../atoms/Service/Service";
import Man from './man.png'
export function ServicesBanner() {
  return (
    <section className={styles.section}>
      <div className={styles.textPart}>

      <div>
        <h1>Our Services</h1>
        <img src={ZapIcon} alt="Zaptance" />
        <p>Transforming your</p>
        <p>Ideas into reality!</p>
      </div>
      <div>
        <ServiceAtom name="Software Services" icon="game-icons:card-draw" />
        <ServiceAtom name="Hardware Support" icon="game-icons:card-draw" />
        <ServiceAtom name="Cloud Computing Services" icon="game-icons:card-draw" />
        <ServiceAtom name="Network Management" icon="game-icons:card-draw" />
        <ServiceAtom name="IT Communications" icon="game-icons:card-draw" />
        <ServiceAtom name="IT Help Desk" icon="game-icons:card-draw" />
        <ServiceAtom name="Cyber Security" icon="game-icons:card-draw" />
        <ServiceAtom
          name="iT Training hub and Consultancy Services"
          icon="game-icons:card-draw"
        />
        
      </div>
      </div>
      <div className={styles.imgPart}>
        <img src={Man} alt='a man' />
      </div>
    </section>
  );
}
