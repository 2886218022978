import React from "react";
import { AboutBanner } from "../UI/organisms/AboutBanner/AboutBanner";
import { AboutTeam } from "../UI/organisms/AboutTeam/AboutTeam";
import { Footer } from "../UI/organisms/Footer/Footer";
import { Navigation } from "../UI/organisms/Navigation/Navigation";
import { Partnership } from "../UI/organisms/Partnership/Partnership";
import { Seo } from "../UI/atoms/Seo/Seo";
export const About = () => {
  return (
    <>
          <Seo title="About Page | Zaptrance" description="About Zaptrance" />

      <Navigation />
      <AboutBanner />
      <AboutTeam />
      <Partnership bgColor="#000"
      ctaBgColor='#2353FF'
      textColor="#fff" highlightColor="#2353FF" />
      <Footer bgColor="#fff" />
    </>
  );
};
