import React from "react";
import { Footer } from "../UI/organisms/Footer/Footer";
import { HubBanner } from "../UI/organisms/HubBanner/HubBanner";
import { HubEvent } from "../UI/organisms/HubEvent/HubEvent";
import { HubTrainings } from "../UI/organisms/HubTrainings/HubTrainings";
import { HubUpcomingEvent } from "../UI/organisms/HubUpcomingEvent/HubUpcomingEvent";
import { Navigation } from "../UI/organisms/Navigation/Navigation";
import { Partnership } from "../UI/organisms/Partnership/Partnership";
import { Seo } from "../UI/atoms/Seo/Seo";
export function Hub() {
  return (
    <>
      <Seo title="Hub Page | Zaptrance" description="Our Hub" />
      <Navigation />
      <HubBanner />
      <HubUpcomingEvent />
      <HubEvent />
      <HubTrainings />
      <Partnership
        bgColor="#E2BF02"
        ctaBgColor="#000000"
        textColor="#000"
        highlightColor="#AE6104"
      />
      <Footer bgColor="#fff" />
    </>
  );
}
