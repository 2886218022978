import React from "react";
import styles from "./HubUpcomingEvent.module.scss";
import TurnedCaret from "./asset/turnedCaret.svg";
import GameImg from "./asset/game.png";
import GroupImg from "./asset/group.png";
import LadyImg from "./asset/lady.png";
import SaleImg from "./asset/sale.png";
import TutorImg from "./asset/tutor.png";
import VrImg from "./asset/vr.png";

interface IEvent {
  img: string;
  month: string;
  day: number;
  title: string;
  summary: string;
}
const Event = ({ img, month, day, title, summary }: IEvent) => {
  return (
    <div className={styles.event}>
      <img src={img} alt={title} />
      <div>
        <p>
          <span>{month}</span>
          <span>{day}</span>
        </p>
        <p>
          <span>{title}</span>
          <span>{summary}</span>
        </p>
      </div>
    </div>
  );
};
export const HubUpcomingEvent = () => {
  return (
    <section className={styles.upcoming}>
      <div className={styles.header}>
        <h1>Upcoming Events</h1>
        <button>
          <span>Weekdays</span>
          <img src={TurnedCaret} alt="Caret" />
        </button>
        <button>
          <span>Event Type</span>
          <img src={TurnedCaret} alt="Caret" />
        </button>
        <button>
          <span>Any Category</span>
          <img src={TurnedCaret} alt="Caret" />
        </button>
      </div>
      <div className={styles.eventsContainer}>
        <Event
          title="Wonder Girls 2010 Wonder Girls World Tour San Francisco"
          month="apr"
          day={14}
          img={SaleImg}
          summary="We’ll get you directly seated and inside for you to enjoy the show."
        />

        <Event
          title="JYJ 2011 JYJ Worldwide Concert Barcelona"
          month="aug"
          day={20}
          img={LadyImg}
          summary="Directly seated and inside for you to enjoy the show."
        />

        <Event
          title="2011 Super Junior SM Town Live '10 World Tour New York City"
          month="sep"
          day={18}
          img={TutorImg}
          summary="Directly seated and inside for you to enjoy the show."
        />

        <Event
          title="Wonder Girls 2010 Wonder Girls World Tour San Francisco"
          month="apr"
          day={14}
          img={GroupImg}
          summary="We’ll get you directly seated and inside for you to enjoy the show."
        />

        <Event
          title="JYJ 2011 JYJ Worldwide Concert Barcelona"
          month="aug"
          day={20}
          img={VrImg}
          summary="Directly seated and inside for you to enjoy the show."
        />

        <Event
          title="2011 Super Junior SM Town Live '10 World Tour New York City"
          month="sep"
          day={18}
          img={GameImg}
          summary="Directly seated and inside for you to enjoy the show."
        />
      </div>
      <button className={styles.loadMoreBtn}>Load More</button>
    </section>
  );
};
