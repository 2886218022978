import React from "react";
import styles from "./Partnership.module.scss";
import { Icon } from "@iconify/react";
interface IPartnership {
  bgColor: string;
  highlightColor: string;
  textColor: string;
  ctaBgColor: string;
}

interface IPatternProps {
  fill: string;
}
const Pattern = ({ fill }: IPatternProps) => (
  <svg
    width="110"
    height="162"
    viewBox="0 0 110 162"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="6" height="6" rx="2" fill={fill} />
    <rect x="26" width="6" height="6" rx="2" fill={fill} />
    <rect x="52" width="6" height="6" rx="2" fill={fill} />
    <rect x="78" width="6" height="6" rx="2" fill={fill} />
    <rect x="104" width="6" height="6" rx="2" fill={fill} />
    <rect y="26" width="6" height="6" rx="2" fill={fill} />
    <rect x="26" y="26" width="6" height="6" rx="2" fill={fill} />
    <rect x="52" y="26" width="6" height="6" rx="2" fill={fill} />
    <rect x="78" y="26" width="6" height="6" rx="2" fill={fill} />
    <rect x="104" y="26" width="6" height="6" rx="2" fill={fill} />
    <rect y="52" width="6" height="6" rx="2" fill={fill} />
    <rect x="26" y="52" width="6" height="6" rx="2" fill={fill} />
    <rect x="52" y="52" width="6" height="6" rx="2" fill={fill} />
    <rect x="78" y="52" width="6" height="6" rx="2" fill={fill} />
    <rect x="104" y="52" width="6" height="6" rx="2" fill={fill} />
    <rect y="78" width="6" height="6" rx="2" fill={fill} />
    <rect x="26" y="78" width="6" height="6" rx="2" fill={fill} />
    <rect x="52" y="78" width="6" height="6" rx="2" fill={fill} />
    <rect x="78" y="78" width="6" height="6" rx="2" fill={fill} />
    <rect x="104" y="78" width="6" height="6" rx="2" fill={fill} />
    <rect y="104" width="6" height="6" rx="2" fill={fill} />
    <rect x="26" y="104" width="6" height="6" rx="2" fill={fill} />
    <rect x="52" y="104" width="6" height="6" rx="2" fill={fill} />
    <rect x="78" y="104" width="6" height="6" rx="2" fill={fill} />
    <rect x="104" y="104" width="6" height="6" rx="2" fill={fill} />
    <rect y="130" width="6" height="6" rx="2" fill={fill} />
    <rect x="26" y="130" width="6" height="6" rx="2" fill={fill} />
    <rect x="52" y="130" width="6" height="6" rx="2" fill={fill} />
    <rect x="78" y="130" width="6" height="6" rx="2" fill={fill} />
    <rect x="104" y="130" width="6" height="6" rx="2" fill={fill} />
    <rect y="156" width="6" height="6" rx="2" fill={fill} />
    <rect x="26" y="156" width="6" height="6" rx="2" fill={fill} />
    <rect x="52" y="156" width="6" height="6" rx="2" fill={fill} />
    <rect x="78" y="156" width="6" height="6" rx="2" fill={fill} />
    <rect x="104" y="156" width="6" height="6" rx="2" fill={fill} />
  </svg>
);

export const Partnership = ({
  bgColor,
  highlightColor,
  textColor,
  ctaBgColor,
}: IPartnership) => {
  return (
    <section
      className={styles.partnership}
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      <div className={styles.textPart}>
        <Pattern fill={highlightColor} />
        <div>
          <p>Got a project or </p>
          <h1 style={{ color: highlightColor }}>Partnership in</h1>
          <p>Mind?</p>
        </div>
        <div>
          <button
            style={{
              backgroundColor: textColor,
              color: bgColor,
            }}
          >
            <Icon icon="material-symbols:call" />
          </button>
          <h4>Phone</h4>
          <p> +234 9084371023</p>
          <p> +234 9084371023</p>
        </div>
        <div>
          <button
            style={{
              backgroundColor: textColor,
              color: bgColor,
            }}
          >
            <Icon icon="ic:round-email" />
          </button>
          <h4>Email</h4>
          <p>info@zaptrance.ng</p>
          <p>info@zaptrance.ng</p>
        </div>
      </div>
      <div className={styles.formPart}>
        <form>
          <input type="text" placeholder="Your Name" />
          <input type="email" placeholder="Your E-Mail Address" />
          <textarea rows={6} placeholder="Your Project"></textarea>
          <button
            style={{
              color: "#fff",
              backgroundColor: ctaBgColor,
            }}
          >
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};
