import React , {useEffect} from "react";
import { Navigate, Routes, useLocation } from "react-router";
import { LandingPage } from "./pages/LandingPages";
import { Route } from "react-router-dom";
import { Elinks } from "./types/enums";
import { Services } from "./pages/Services";
import { About } from "./pages/About";
import { Product } from "./pages/Product";
import { Hub } from "./pages/Hub";
import { Contact } from "./pages/Contact";

function App() {
  const location = useLocation(); 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  
  return (
    <div className="App">
      <Routes>
        <Route path={Elinks.home} element={<LandingPage />} />
        <Route path={Elinks.services} element={<Services />} />
        <Route path={Elinks.about} element={<About />} />
        <Route path={Elinks.product} element={<Product />} />
        <Route path={Elinks.hub} element={<Hub />} />
        <Route path={Elinks.contact} element={<Contact />} />
        <Route path={Elinks.others} element={<Navigate to={Elinks.home} />} />
      </Routes>
    </div>
  );
}

export default App;
