import React from "react";
import styles from "./ProductsBanner.module.scss";
import Kasuwa24 from "./imgPart.svg";
import LearnMoreIcon from "./learn-more-icon.svg";
import { Elinks } from "../../../types/enums";

export function ProductBanner() {
  return (
    <section className={styles.productBanner}>
      <div className={styles.textPart}>
        <h4>Kasuwa24</h4>
        <h2>an e-Market place</h2>
        <p>
          An e-Market place where varieties of products are advertised to reach
          more customers
        </p>
        <p>
          <a target={"_blank"} href={Elinks.kasuwa} rel="noreferrer">
            Visit Page
          </a>
          <a target="_blank" href={Elinks.kasuwa} rel="noreferrer">
            <img src={LearnMoreIcon} alt="Learn more" />
            <span>Learn More</span>
          </a>
        </p>
      </div>
      <div className={styles.imgPart}>
        <img src={Kasuwa24} alt="Kasuwa 24" />
      </div>
    </section>
  );
}
