import React from "react";
import ArrowIcon from "./tabler_target-arrow.svg";
import styles from "./HomeMission.module.scss";
export const HomeMission = () => {
  return (
    <section className={styles.missionVision}>
      <div>
        <p>
          <img src={ArrowIcon} alt="Arrow" />
          <span>Our Mission</span>
        </p>
        <p>
        Evolving businesses to globalization and stable growth through the use of IT,
innovation, digital solutions, professionalism, and excellence.

        </p>
      </div>

      <div>
        <p>
          <img src={ArrowIcon} alt="Arrow" />
          <span>Our Vision</span>
        </p>
        <p>
        To be the premium TI and Digital service company in Nigeria. 
        </p>
      </div>
    </section>
  );
};
