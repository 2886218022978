import React from "react";
import { Logo } from "../../../assets";

import styles from "./ZaptranceLogo.module.scss";
export const ZaptranceLogo = () => (
  <p className={styles.container}>
    <img src={Logo} alt="Zaptrance" />
    <span>Zaptrance</span>
  </p>
);
