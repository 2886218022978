import React from "react";
import styles from "./HubEvent.module.scss";
import LeftImg from "./asset.svg";

export const HubEvent = () => {
  return (
    <section className={styles.hubEvent}>
      <div>
        <img src={LeftImg} alt="asset" />
      </div>
      <div>
        <h1>Attend our next event</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <a href="/#">Reserve</a>
      </div>
    </section>
  );
};
