import { Icon } from "@iconify/react";
import React from "react";
import TextTopLeftImg from "./asset/topLeft.svg";
import TextBottomLeftImg from "./asset/bottomLeft.svg";
import Phones from "./asset/phones.png";
import styles from "./ProductsDownload.module.scss";
import { Elinks } from "../../../types/enums";

export function ProductsDownload() {
  return (
    <section className={styles.productDownload}>
      <div className={styles.textPart}>
        <img src={TextBottomLeftImg} alt="Shapes" />
        <img src={TextTopLeftImg} alt="dots" />
        <h4>Fast, easy and free</h4>
        <h3>Download our app to get started today!</h3>
        <p>
          You will find, what you are looking for! Give us the first two months
          and your results will speak for us.
        </p>
        <p>
          <a href={Elinks.applestore}>
            <Icon icon="ic:baseline-apple" />
            <span>Playstore</span>
          </a>
          <a href={Elinks.playstore}>
            <Icon icon="ion:logo-google-playstore" />
            <span>Google Play</span>
          </a>
        </p>
      </div>
      <div className={styles.imgPart}>
        <img src={Phones} alt="Two phone screens showing the kasuwa24 app" />
      </div>
    </section>
  );
}
