import React from "react";
import { Navigation } from "../UI/organisms/Navigation/Navigation";
import { Footer } from "../UI/organisms/Footer/Footer";
import { HomeBanner } from "../UI/organisms/HomeBanner/HomeBanner";
import { HomeChooseUs } from "../UI/organisms/HomeChooseUs/HomeChooseUs";
import { HomeClients } from "../UI/organisms/HomeClients/HomeClients";
import { HomeIdeas } from "../UI/organisms/HomeIdeas/HomeIdeas";
import { HomeMission } from "../UI/organisms/HomeMission/HomeMission";
import { Seo } from "../UI/atoms/Seo/Seo";
export const LandingPage = () => {
  return (
    <>
    <Seo title='Home | Zaptrance' description="Zaptrance Homepage"/>
      <Navigation />
      <HomeBanner />
      <HomeChooseUs />
      <HomeMission />
      <HomeClients />
      <HomeIdeas />
      <Footer bgColor="hsl(46, 100%, 97%)" />
    </>
  );
};
