import React from "react";
import SmilingMan from "./asset/smilingman.png";
import Strike from "./asset/strike.svg";
import ZaptranceImg from "./asset/zaptranceLogo.svg";
import styles from "./HomeChooseUs.module.scss";
export const HomeChooseUs = () => {
  return (
    <section className={styles.chooseUs}>
      <div className={styles.imgPart}>
        <img src={SmilingMan} alt="A smiling man" />
      </div>

      <div className={styles.textPart}>
        <h4>
          Who we are &
          <br />
          <span>Why You Should Choose Us?</span>
          <br />
          <span>Digtal Solutions</span>
        </h4>
        <p>
        Zaptrance World Wide Systems Limited is a premium leading, exception digital
solution provider, and a world class TI systems integration firm, that provides digital,
optimum,
At Zaptrance World Wide Systems Limited, we are a global service company with TI
and technology at its core.
diversified and innovative services to
client, making use of our unique and experienced reservoir of knowledge from all
ramification, to provide the best service. 
  </p>
        <div>
          <div>
            <p>
              <img src={Strike} alt="Thunder strike" />
              <span>Cleint centric</span>
            </p>

            <p>
              <img src={Strike} alt="Thunder strike" />
              <span>Innovation</span>
            </p>
            <p>
              <img src={Strike} alt="Thunder strike" />
              <span>Time Savvy</span>
            </p>
          </div>
          <div>
            <p>
              <img src={Strike} alt="Thunder strike" />
              <span>Professionalism</span>
            </p>

            <p>
              <img src={Strike} alt="Thunder strike" />
              <span>Premium Service</span>
            </p>

            <p>
              <img src={Strike} alt="Thunder strike" />
              <span>Excellence</span>
            </p>
          </div>
          <img src={ZaptranceImg} alt="Zaptrance" />
        </div>
      </div>
    </section>
  );
};
