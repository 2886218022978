import React from "react";
import { Navigation } from "../UI/organisms/Navigation/Navigation";
import { Footer } from "../UI/organisms/Footer/Footer";
import { ServicesBanner } from "../UI/organisms/ServicesBanner/ServicesBanner";
// REMOVED THIS AS PER CORRECTOIN ON 21/02/2023
// import { ServicesTestimonials } from "../UI/organisms/ServicesTestimonials/ServicesTestimonials";
import { ServicesIdeas } from "../UI/organisms/ServicesIdeas/ServicesIdeas";
import { Partnership } from "../UI/organisms/Partnership/Partnership";
import { Seo } from "../UI/atoms/Seo/Seo";
export const Services = () => {
  return (
    <>
      <Seo title="Services Page | Zaptrance" description="Our Services" />

      <Navigation />
      <ServicesBanner />
      {/* <ServicesTestimonials /> */}
      <Partnership
        bgColor="#E2BF02"
        ctaBgColor="#000000"
        textColor="#000"
        highlightColor="#AE6104"
      />
      <ServicesIdeas />
      <Footer bgColor="#fff" />
    </>
  );
};
