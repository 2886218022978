import React from "react";
import styles from "./BrownButton.module.scss";
import { Link } from "react-router-dom";
interface IBrownButtonProps {
  children: string;
  link: string;
}
export const BrownButton = (props: IBrownButtonProps) => (
  <Link className={styles.btn} to={props.link}>
    {props.children}
  </Link>
);
