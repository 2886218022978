import React from "react";
import { Icon } from "@iconify/react";
import styles from './Service.module.scss'
interface IService {

  name: string;
  icon: string;
}
export function ServiceAtom({ name, icon }: IService) {
  return (
    <div className={styles.service}>
      <Icon icon={icon} />
      <span>{name}</span>
    </div>
  );
}