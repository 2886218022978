import React, { useCallback, useEffect, useState } from "react";
import { ZaptranceLogo } from "../../atoms/ZaptranceLogo/ZaptranceLogo";
import { NavLink } from "react-router-dom";
import { BrownButton } from "../../atoms/BrownButton/BrownButton";
import { Elinks } from "../../../types/enums";
import { Icon } from "@iconify/react";

import { motion, AnimatePresence } from "framer-motion";
import styles from "./Navigation.module.scss";
interface INavLink {
  url: string;
  text: string;
}
const NAV_LINKS: INavLink[] = [
  {
    url: Elinks.home,
    text: "Home",
  },
  {
    url: Elinks.services,
    text: "Services",
  },
  {
    url: Elinks.product,
    text: "Product",
  },
  {
    url: Elinks.about,
    text: "About",
  },
  {
    url: Elinks.hub,
    text: "Hub",
  },
];
export const Navigation = () => {
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);
  const [width, setWidth] = useState(window.innerWidth);

  const handleWidth = useCallback(() => {
    setWidth(window.innerWidth);
  }, [setWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleWidth);
    return () => window.removeEventListener("resize", handleWidth);
  }, [handleWidth]);

  const tabletBreakPoint: number = 768;

  return (
    <nav className={styles.nav}>
      <ZaptranceLogo />
      <AnimatePresence>
        {/* MOBILE NAV  */}
        {width <= 768 && showMobileNav && (
          <motion.p
            initial={{
              y: "-100vh",
              x: 0,
            }}
            animate={{
              x: "0",
              y: "0",
            }}
            transition={{
              duration: 0.8,
            }}
            exit={{
              x: "100vw",
              y: "100vh",
            }}
          >
            {NAV_LINKS.map(({ text, url }) => (
              <NavLink
                key={text}
                to={url}
                className={({ isActive }) => (isActive ? styles.active : "")}
              >
                {text}
              </NavLink>
            ))}

            {width <= tabletBreakPoint && (
              <BrownButton link={Elinks.contact}>Contact Us</BrownButton>
            )}
          </motion.p>
        )}
      </AnimatePresence>

      {/* DESKTOP NAV  */}
      {/* deskTOP and mobile nav have same content 
          except that mobile nav uses framermotion for animation 
          while desktop nav requires no animation
        */}
      {width > 768 && (
        <p>
          {NAV_LINKS.map(({ text, url }) => (
            <NavLink
              key={text}
              to={url}
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              {text}
            </NavLink>
          ))}

          {width <= tabletBreakPoint && (
            <BrownButton link={Elinks.contact}>Contact Us</BrownButton>
          )}
        </p>
      )}

      {width <= tabletBreakPoint && (
        <button
          onClick={() => {
            setShowMobileNav((x) => !x);
          }}
        >
          {/* toggler */}
          <Icon icon="ri:menu-2-line" />
        </button>
      )}
      {width > tabletBreakPoint && (
        <BrownButton link={Elinks.contact}>Contact Us</BrownButton>
      )}
    </nav>
  );
};
