import React from "react";
import { Icon } from "@iconify/react";
import styles from './FooterSearch.module.scss'; 

export const FooterSearch = () => {
  return (
    <p className={styles.search}>
      <input type="text" placeholder="Enter your email address" />
      <Icon icon="material-symbols:arrow-right-alt" />
    </p>
  );
};
