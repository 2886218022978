import React from "react";
import styles from "./AboutBanner.module.scss";
import ManImg from "./man.png";
export function AboutBanner() {
  return (
    <section className={styles.aboutBanner}>
      <div>
        <h1>About Us </h1>
        <p>
        Zaptrance World Wide Systems Limited is committed towards providing one stop
solutions covering a wide range of business needs, by making used of our global
network and indigenous circle to deliver high-end value added service.  </p>
      </div>
      <div>
        <img src={ManImg} alt="A man operating a laptop and smiling" />
      </div>
    </section>
  );
}
