import React from "react";
import { Client } from "../../atoms/Client/Client";
import AbuLogo from "./asset/ABU.png";
import ICICTLogo from "./asset/ICICT.png";
import KadPolyLogo from "./asset/KADPOLY.png";
import NBTELogo from "./asset/NBTE.png";
import NCATLogo from "./asset/NCAT.png";
import PTILogo from "./asset/PTI.png";
import styles from './HomeClients.module.scss'
export const HomeClients = () => {
  return (
    <section className={styles.homeclients}>
      <h1>Some of Our Clients</h1>
      <div>
        <Client imgUrl={NBTELogo} imgAlt="NBTE">
          National board for technical education
        </Client>
        <Client imgUrl={KadPolyLogo} imgAlt="KADUNA POLYTECHNIC">
          Kaduna Polytechnic
        </Client>
        <Client imgUrl={NCATLogo} imgAlt="NCAT">
          Nigerian College of Aviation Technology Zaria
        </Client>
        {/* <Client imgUrl={AbuLogo} imgAlt="ABU, Zaria">
          Ahmadu Bello University Zaria
        </Client> */}
        <Client imgUrl={PTILogo} imgAlt="PTI">
          Petroleum training Institute
        </Client>
        {/* <Client imgUrl={ICICTLogo} imgAlt="ICIcT">
          Iya Abubakar Institue of communication technology
        </Client> */}
      </div>
    </section>
  );
};
