import { Icon } from "@iconify/react";
import React from "react";
import { Elinks } from "../../../types/enums";
import styles from "./ProductsHow.module.scss";
interface IHow {
  icon: string;
  text: string;
}
function How({ icon, text }: IHow) {
  return (
    <p className={styles.how}>
      <Icon icon={icon} />
      <span>{text}</span>
    </p>
  );
}
export function ProductHow() {
  return (
    <section className={styles.productHow}>
      <h1>How it works</h1>
      <div>
        <How icon="material-symbols:search-rounded" text="Search For An Item" />
        <How icon="material-symbols:garden-cart-outline" text="Find The Item" />
        <How icon="ph:user-bold" text="Contact The Vendor" />
        <How icon="grommet-icons:transaction" text="Make Transactions" />
      </div>
      <p>
        Contact us: visit our website,
        <a href={Elinks.kasuwa}>
          <b>kasuwa24.ng</b>
        </a>
        , or call us at 09084371023.
      </p>
    </section>
  );
}
