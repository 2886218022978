import React from "react";
import styles from "./AboutTeam.module.scss";
import TeamMemberSampleImg from "./teamMember.png";
interface ITeamMember { 
  name : string , 
  position : string, 
  imgUrl : string 
}
function TeamMember({ name, position, imgUrl }:ITeamMember) {
  return <div className={styles.teamMember}>
    <img src={imgUrl} alt={name} /> 
    <h4>{name}</h4>
    <p>{position}</p>
  </div>;
}
export function AboutTeam() {
  return (
    <section className={styles.aboutTeam}>
      <h1>Our Team</h1>
      <div>
        <TeamMember 
        name ='Muhammed Fareed Adamu'
        position={' Managing Director/CEO'}
        imgUrl={TeamMemberSampleImg}
        />
         <TeamMember 
        name ='Muhammed Fareed Adamu'
        position={' Managing Director/CEO'}
        imgUrl={TeamMemberSampleImg}
        />
         <TeamMember 
        name ='Muhammed Fareed Adamu'
        position={' Managing Director/CEO'}
        imgUrl={TeamMemberSampleImg}
        />
         <TeamMember 
        name ='Muhammed Fareed Adamu'
        position={' Managing Director/CEO'}
        imgUrl={TeamMemberSampleImg}
        />
         <TeamMember 
        name ='Muhammed Fareed Adamu'
        position={' Managing Director/CEO'}
        imgUrl={TeamMemberSampleImg}
        />
         <TeamMember 
        name ='Muhammed Fareed Adamu'
        position={' Managing Director/CEO'}
        imgUrl={TeamMemberSampleImg}
        />
         <TeamMember 
        name ='Muhammed Fareed Adamu'
        position={' Managing Director/CEO'}
        imgUrl={TeamMemberSampleImg}
        />
         <TeamMember 
        name ='Muhammed Fareed Adamu'
        position={' Managing Director/CEO'}
        imgUrl={TeamMemberSampleImg}
        />
      </div>
    </section>
  );
}
