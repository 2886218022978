import React from "react";
import { BrownButton } from "../../atoms/BrownButton/BrownButton";
import HumanLogo from "./asset/human.png";
import ZapLogo from "./asset/zap.svg";
import styles from "./HomeBanner.module.scss";
import { Elinks } from "../../../types/enums";
export const HomeBanner = () => {
  return (
    <section className={styles.banner}>
      <div>
        {/* text part  */}
        <p>
          We Create <br />
          <b>Unqiue and Effecient</b>
          <br />
          Digtal Solutions
        </p>
        {/* <p>
          REMOVED THIS TEXT AS PER CORRECTION

          Some text written here about getting Some text written here about
          getting Some text written here about getting Some text written here
          about getting Some text written here about getting Get Service
        </p> */}
        <BrownButton link={Elinks.services}>Get Service </BrownButton>
      </div>
      <div>
        {/* img container  */}
        <img
          src={HumanLogo}
          alt="A nice looking man"
         
        />
        <img src={ZapLogo} alt="Zap Logo" />
      </div>
    </section>
  );
};
