import React from "react";
import styles from "./HubTrainings.module.scss";
import ManImg from "./asset/man.png";
import ZaptranceImg from "./asset/zaptrance.png";
import { ServiceAtom } from "../../atoms/Service/Service";

export const HubTrainings = () => {
  return (
    <section className={styles.hubtrainings}>
      <div className={styles.imgPart}>
        <img
          src={ManImg}
          alt="A man with a mug drinking while operating a laptop"
        />
      </div>
      <div className={styles.textPart}>
        <h1>Our trainings</h1>
        {/* <p>
          Some text written here about getting Some text written here about
          getting Some text written here about getting Some text written here
          about getting Some text written here about getting
        </p> */}
        <div>
          <ServiceAtom
            name="Hardware Maintenance"
            icon="game-icons:card-draw"
          />
          <ServiceAtom
            name="Introduction to Computer and Internet "
            icon="game-icons:card-draw"
          />
          <ServiceAtom
            name="Networking and cyber Security"
            icon="game-icons:card-draw"
          />
          <ServiceAtom
            name="Mobile Application Development"
            icon="game-icons:card-draw"
          />
          <ServiceAtom
            name="Backend Development "
            icon="game-icons:card-draw"
          />
          <ServiceAtom
            name="Front end development"
            icon="game-icons:card-draw"
          />
          <ServiceAtom
            name="Code school for kids"
            icon="game-icons:card-draw"
          />
          <ServiceAtom name="Executive Courses " icon="game-icons:card-draw" />
          <img src={ZaptranceImg} alt="Zaptrance" />
        </div>
      </div>
    </section>
  );
};
