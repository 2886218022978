import React from "react";
import styles from "./HubBanner.module.scss";
import BackgroundImg from "./asset/background.png";
import BackgroundImg2x from "./asset/background2x.png";

export const HubBanner = () => {
  const alt = "A banner showing a man";
  return (
    <section className={styles.bg}>
      <picture>
        <source srcSet={BackgroundImg2x} media="(min-width: 1020px)" />
        <source srcSet={BackgroundImg} />
        <img src={BackgroundImg} alt={alt} />
      </picture>
    </section>
  );
};
