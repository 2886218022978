export const Elinks = {
  home: "/",
  services: "/services",
  about: "/about",
  product: "/product",
  hub: "/hub",
  contact: "/contact",
  team: "/team",
  facebook: "/#",
  twitter: "/#",
  instagram: "/#",
  applestore: "/#",
  playstore: "/#",
  kasuwa: "https://kasuwa24.ng/",
  others: "*",
};
