import React from "react";

import { Icon } from "@iconify/react";
import { ZaptranceLogo } from "../../atoms/ZaptranceLogo/ZaptranceLogo";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { Elinks } from "../../../types/enums";
import { FooterSearch } from "../../atoms/FooterSearch/FooterSearch";
import BackToTopButton from "./BackToTop.svg";

interface IFooter {
  bgColor: string;
}
export const Footer = ({ bgColor }: IFooter) => {
  return (
    <footer
      className={styles.footer}
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div className={styles.columns}>
        <div className={styles.column}>
          <ZaptranceLogo />
          <p>
            <Icon icon="material-symbols:location-on" />
            <span> EE1, Emir Close, Ungawar Kudu, Unguwar Rimi, Kaduna. </span>
          </p>

          <p>
            <Icon icon="material-symbols:mail-rounded" />
            <span>info@zaptrance.ng</span>
          </p>
          <p>
            <Icon icon="mdi:twitter" />
            <Icon icon="ph:instagram-logo-fill" />
            <span>@zaptrance</span>
          </p>
          <p>
            <Icon icon="material-symbols:call" />
            <span>+234 9084371023 </span>
          </p>
        </div>
        <div className={styles.column}>
          <h4>Offical Info</h4>
          <Link to={Elinks.home}>Home</Link>
          <Link to={Elinks.about}>About Us</Link>
          <Link to={Elinks.services}>Services </Link>
          <Link to={Elinks.team}>Team</Link>
        </div>

        <div className={styles.column}>
          <h4>Subscribe to Get the lastest updates, Offers & Promotion</h4>
          <FooterSearch />
        </div>
        <div className={styles.column}>
          <h4>Follow us on social media to get the lastest updates</h4>
          <p>
            <a href={Elinks.facebook}>
              <Icon icon="ic:baseline-facebook" />
            </a>
            <a href={Elinks.twitter}>
              <Icon icon="pajamas:twitter" />
            </a>
            <a href={Elinks.instagram}>
              <Icon icon="ant-design:instagram-filled" />
            </a>
          </p>
        </div>
      </div>
      <button
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        {/* back to top button */}
        <img src={BackToTopButton} alt="Go to top of page" />
      </button>
      <p>
        <Icon icon="ph:copyright" />
        <span>All right received Zaptrance, 2022</span>
      </p>
    </footer>
  );
};
