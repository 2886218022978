import { Icon } from "@iconify/react";
import React from "react";
import { useNavigate } from "react-router";
import { Partnership } from "../../organisms/Partnership/Partnership";
import styles from './ContactPopUp.module.scss' ; 

export const ContactPopUp = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.popup}>
      <Partnership
        bgColor="#2353FF"
        ctaBgColor="#000000"
        textColor="#fff"
        highlightColor="#fff"
      />
      <button onClick={() => navigate(-1) }><Icon icon='material-symbols:cancel' /></button>
    </div>
  );
};
